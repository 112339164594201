<template>
    <div @click="toggleCheck" class="inline-flex items-center cursor-pointer">
      <div v-if="modelValue" v-html="checkedSVG"></div>
      <div v-else v-html="uncheckedSVG"></div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      modelValue: Boolean
    },
    computed: {
      checkedSVG() {
        return `
          <svg width="32" height="21" viewBox="0 0 32 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="2" width="31" height="17" rx="8.5" fill="#2555FF" fill-opacity="0.21"/>
            <g filter="url(#filter0_d_4_434)">
              <circle cx="21.5" cy="10.5" r="6.5" fill="#2555FF"/>
            </g>
            <defs>
              <filter id="filter0_d_4_434" x="11" y="0" width="21" height="21" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.145098 0 0 0 0 0.333333 0 0 0 0 1 0 0 0 0.54 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_434"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_434" result="shape"/>
              </filter>
            </defs>
          </svg>
        `;
      },
      uncheckedSVG() {
        return `
          <svg width="31" height="17" viewBox="0 0 31 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="31" height="17" rx="8.5" fill="#2B293C"/>
            <circle cx="9.5" cy="8.5" r="6.5" fill="#4B4960"/>
          </svg>
        `;
      }
    },
    methods: {
      toggleCheck() {
        console.log(!this.modelValue)
        this.$emit('update:modelValue', !this.modelValue);
      }
    }
  };
  </script>
  
  <style scoped>
  .cursor-pointer {
    cursor: pointer;
  }
  </style>
  