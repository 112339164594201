import { createApp } from 'vue';
import App from './App.vue';
import './assets/tailwind.css';
import './registerServiceWorker'
import { messaging } from './firebaseConfig'; // Certifique-se de importar a configuração correta

// import './index.css';

try{

    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
        messaging.useServiceWorker(registration); // Use o Service Worker registrado
      })
      .catch((err) => {
        console.error('Service Worker registration failed:', err);
      });
}catch(e){
    console.log(e)
}

createApp(App).mount('#app');
