<template>
    <div class="p-3 w-[330px]">
      <div class="flex mb-4">
        <img :src="imagePath(selectedGame.src)" :alt="selectedGame.name" class="game-image selected mr-4">
        <div class="flex flex-col items-start mt-4">
          <span class="modifying-text">Modificando seu jogo</span>
          <button class="status-button mt-1 w-[84px]">POR FAVOR, AGUARDE</button>
        </div>
      </div>
      <div class="bg-loading w-[290px] p-3 rounded">
        <div class="progress-bar w-full">
          <div class="progress" :class="{ 'progress-complete': progress === 100 }" :style="{ width: progress + '%' }"></div>
        </div>
        <div class="flex justify-between items-center mt-2 w-full">
          <button class="progress-text flex items-center" :class="{ 'progress-text-complete': progress === 100 }">
            <template v-if="progress < 100">
              {{ progressLabel }}
            </template>
            <template v-else>
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-1">
                <path d="M4.41667 6.91665L7.35417 3.97915L6.77084 3.39581L4.41667 5.74998L3.22917 4.56248L2.64584 5.14581L4.41667 6.91665ZM5 9.16665C4.42361 9.16665 3.88195 9.0572 3.375 8.83831C2.86806 8.61942 2.42709 8.32262 2.05209 7.9479C1.67709 7.57317 1.38028 7.1322 1.16167 6.62498C0.943059 6.11776 0.833614 5.57609 0.833336 4.99998C0.833059 4.42387 0.942503 3.8822 1.16167 3.37498C1.38084 2.86776 1.67764 2.42679 2.05209 2.05206C2.42653 1.67734 2.8675 1.38054 3.375 1.16165C3.8825 0.942757 4.42417 0.833313 5 0.833313C5.57584 0.833313 6.1175 0.942757 6.625 1.16165C7.1325 1.38054 7.57348 1.67734 7.94792 2.05206C8.32236 2.42679 8.61931 2.86776 8.83875 3.37498C9.0582 3.8822 9.1675 4.42387 9.16667 4.99998C9.16584 5.57609 9.05639 6.11776 8.83834 6.62498C8.62028 7.1322 8.32348 7.57317 7.94792 7.9479C7.57236 8.32262 7.13139 8.61956 6.625 8.83873C6.11861 9.0579 5.57695 9.1672 5 9.16665Z" fill="#25FF7C"/>
              </svg>
              JOGO HACKEADO
            </template>
          </button>
          <p class="progress-percent" :class="{ 'progress-percent-complete': progress === 100 }">{{ progress }}%</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ScreenFour',
    props: ['selectedGame'],
    data() {
      return {
        progress: 3,
        progressLabel: ':// MFOWE_PG_FMOUSE',
      };
    },
    mounted() {
      this.startProgress();
    },
    methods: {
      startProgress() {
        const steps = [
          { percent: 3, label: ':// MFOWE_PG_FMOUSE' },
          { percent: 63, label: ':// PG_BOOST_ACTIVE' },
          { percent: 78, label: ':// MULTIPLY_TRUE98' },
          { percent: 100, label: 'JOGO HACKEADO' },
        ];
  
        let currentStep = 0;
        const interval = setInterval(() => {
          if (currentStep < steps.length) {
            this.progress = steps[currentStep].percent;
            this.progressLabel = steps[currentStep].label;
            currentStep++;
          } else {
            clearInterval(interval);
            this.$emit('modification-complete');
          }
        }, 800);
      },
      imagePath(imageName) {
        return require(`@/assets/images/${imageName}`);
      },
    },
  };
  </script>
  
  <style scoped>
  .modifying-text {
    font-size: 18px;
    font-weight: bold;
    color: white;
    text-align: left; /* Alinhado à esquerda */
  }
  
  .status-button {
    background-color: #2555FF2B;
    color: #8BA5FF;
    border-radius: 4px;
    font-size: 7px;
    padding: 2px 4px;
  }
  
  .status-button-complete {
    background-color: #25FF7C24;
  }
  
  .bg-loading {
    background-color: #FFFFFF0D;
  }
  
  .progress-bar {
    background-color: #2B293C;
    border-radius: 8px;
    width: 100%;
    height: 8px;
    margin-top: 8px;
    overflow: hidden;
  }
  
  .progress {
    background-color: #2555FF;
    height: 100%;
    border-radius: 8px;
  }
  
  .progress-complete {
    background-color: #25FF7C;
  }
  
  .progress-text {
    background-color: #2555FF;
    color: white;
    border-radius: 4px;
    font-size: 9px;
    padding: 4px 8px;
  }
  
  .progress-text-complete {
    background-color: #25FF7C24;
  }
  
  .progress-percent {
    font-size: 12px;
    color: white;
  }
  
  .progress-percent-complete {
    color: #25FF7C;
  }
  
  .game-image {
    width: 74px;
    height: 74px;
    border-radius: 10px;
    object-fit: cover;
    object-position: 0px -20px;
    filter: grayscale(100%);
    transition: filter 0.3s;
  }
  
  .game-image.selected {
    filter: none;
  }
  </style>
  