<template>
    <div class="screen2 flex flex-col justify-between h-full p-3">
      <div class="flex justify-around mb-4 space-x-2">
        <img v-for="game in games" :src="require(`@/assets/images/${game.src}`)" :alt="game.name" :key="game.name" @click="selectGame(game)" :class="['game-image', { selected: selectedGame === game }]" />
      </div>
      <div class="flex items-center justify-between mt-auto pt-4">
        <div class="flex items-center">
          <span class="footer-icon-bg text-white rounded-full p-2 mr-2">
            🕹️
          </span>
          <p class="footer-text">Selecione um jogo para iniciar</p>
        </div>
        <button :class="['continue-button text-white py-2 ml-4 w-40 rounded flex items-center justify-center', { 'disabled': !selectedGame }]" @click="proceed" :disabled="!selectedGame">
          Continuar
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-2">
            <path d="M3.15004 11.6667L2.33337 10.85L9.10004 4.08335H5.25004V2.91669H11.0834V8.75002H9.91671V4.90002L3.15004 11.6667Z" fill="white" />
          </svg>
        </button>
      </div>
      <div class="flex justify-center mt-4">
        <div class="page-indicator page-indicator-inactive"></div>
        <div class="page-indicator page-indicator-active"></div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ScreenTwo',
    data() {
      return {
        games: [
          { name: 'Fortune Tiger', src: 'tiger.png', iframe_src: 'https://hypezbet.com/play/5402' },
          { name: 'Fortune Ox', src: 'ox.png', iframe_src: 'https://hypezbet.com/play/5378' },
          { name: 'Fortune Dragon', src: 'dragon.png', iframe_src: 'https://hypezbet.com/play/5470' },
          { name: 'Fortune Mouse', src: 'mouse.png', iframe_src: 'https://hypezbet.com/play/5354' },
        ],
        selectedGame: null,
      };
    },
    methods: {
      selectGame(game) {
        this.selectedGame = game;
      },
      proceed() {
        this.$emit('next-screen', this.selectedGame);
      },
    },
  };
  </script>
  
  <style scoped>
  .game-image {
    width: calc(100% / 4 - 8px);
    max-width: 82px;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
    filter: grayscale(100%);
    transition: filter 0.3s;
  }
  
  .game-image.selected {
    filter: none;
  }
  
  .footer-icon-bg {
    background-color: #ff9113;
  }
  
  .footer-text {
    font-size: 12px;
    line-height: 21.78px;
    text-align: left;
    color: white;
  }
  
  .continue-button {
    width: 114px;
    height: 49px;
  }
  
  .continue-button.disabled {
    background-color: #3B3445;
  }
  
  .continue-button:not(.disabled) {
    background-color: #2555FF;
  }
  
  .page-indicator {
    width: 77px;
    height: 5px;
    border-radius: 2.5px;
    margin: 0 5px;
  }
  
  .page-indicator-active {
    background-color: #2547C0;
  }
  
  .page-indicator-inactive {
    background-color: #D4D9E82E;
  }
  </style>
  