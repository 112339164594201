<template>
  <div class="p-3">
    <div class="flex justify-center mb-4">
      <div class="custom-emoji-icon rounded-full p-4">
        <span class="text-2xl">🤑</span>
      </div>
    </div>
    <p class="text-white mb-2" style="font-size: 18px; line-height: 21.78px; text-align: center;">Insira abaixo o telefone vinculado a conta da Hypezbet</p>
    <p class="text-gray-400 mb-6 text-xs">Faça login para desbloquear os seus ganhos</p>
    <input v-model="phone" @input="cleanPhone" type="text" placeholder="11 9 5721 8700" class="w-full p-2 mb-4 rounded custom-input focus:outline-none focus:ring-2 focus:ring-blue-500">
    <button @click="proceed" :disabled="loading" class="custom-button w-full h-[40px] py-2 rounded hover:bg-blue-600">
      <span v-if="!loading">Acessar conta</span>
      <span v-else class="loader"></span>
      <svg v-if="!loading" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-2">
        <path d="M3.15004 11.6667L2.33337 10.85L9.10004 4.08335H5.25004V2.91669H11.0834V8.75002H9.91671V4.90002L3.15004 11.6667Z" fill="white" />
      </svg>
    </button>
    <div class="flex justify-center mt-4">
      <div class="page-indicator page-indicator-active"></div>
      <div class="page-indicator page-indicator-inactive"></div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { ref, get, set } from 'firebase/database';
import { database } from '@/firebaseConfig';
import moment from 'moment-timezone';
import api from '@/services/api';

export default {
  name: 'ScreenOne',
  props: ['referral'],
  data() {
    return {
      loading: false,
      phone: '',
      threashold: 50
    };
  },
  methods: {
    cleanPhone() {
      this.phone = this.phone.replace(/\D/g, '');
    },
    async getThreadhold() {
      // { ref: '...', threshold: 50 }
      let res =  await api.get('/accounts/api/threshold/'+this.referral);
      this.threashold = res.data.threshold;
      return res.data.threshold
    },

    async setLifetimeAccess() {
      try{
        const phone = this.phone;
        let res = await api.post('/dashboard/api/lifetime-activation/', { phone: phone });
        console.log('setLifetimeAccess', res.data);
        return res.data;
      }catch(e){
        console.log('setLifetimeAccess', e);
        return { error: e.message }
      }
    },

    async proceed() {
      if (this.phone) {
        this.loading = true;
        
        try {
          this.threashold = await this.getThreadhold(this.referral);
          const telefone = this.phone;
          const userRef = ref(database, `users-vitalicio/${telefone}`);
          const snapshot = await get(userRef);
          const today = moment().tz('America/Sao_Paulo').format('YYYY-MM-DD');
          if (snapshot.exists()) {
            const userData = snapshot.val();
            console.log('userData', userData);

            // Verifica a flag de acesso vitalício
            if (userData.acessoVitalicio) {
              Cookies.set('phone', telefone);
              let res = await this.setLifetimeAccess();
              console.log('proceed', res);
              this.$emit('next-screen');
              return;
            }
          }

          const depositsRef = ref(database, `data/${telefone}`);
          const depositsSnapshot = await get(depositsRef);

          if (depositsSnapshot.exists()) {
            const data = depositsSnapshot.val();
            let todayTotal = 0;
            let totalPerDay = {};

            for (const key in data) {
              const record = data[key];
              const depositAmount = parseFloat(record.deposito);
              const date = moment(record.timestamp).tz('America/Sao_Paulo').format('YYYY-MM-DD');
              
              if (!totalPerDay[date]) {
                totalPerDay[date] = 0;
              }

              totalPerDay[date] += depositAmount;

              if (date === today) {
                todayTotal += depositAmount;
              }
            }

            this.$emit('update-today-total', todayTotal);
            const hasEnoughDepositToday = todayTotal >= this.threashold;
            const hasEnoughDepositAnyDay = Object.values(totalPerDay).some(total => total >= this.threashold);

            if (hasEnoughDepositToday) {
              await set(userRef, { acessoVitalicio: true });
              Cookies.set('phone', telefone);
              await this.setLifetimeAccess();
              this.$emit('next-screen');
            } else if (hasEnoughDepositAnyDay) {
              Cookies.set('phone', telefone);
              await this.setLifetimeAccess();
              this.$emit('next-screen');
            } else {
            console.log('missing-deposit111')
              this.$emit('missing-deposit');
            }
          } else {
            console.log('missing-deposit222')
            this.$emit('missing-deposit');
          }
        } catch (error) {
          alert('Erro ao obter informações de depósito: ' + error.message);
        } finally {
          this.loading = false;
        }
      }
    }
  },
  mounted() {
    if (Cookies.get('phone')) {
      this.$emit('next-screen');
    }
  }
};
</script>

<style scoped>
.text-white {
  font-size: 18px;
  line-height: 21.78px;
  text-align: center;
}

.custom-emoji-icon {
  background-color: #1e39a3;
}

.custom-input {
  background-color: #ffffff;
  color: #666666;
  border: 1px solid #cccccc;
  height: 44px;
}

.custom-button {
  background-color: #2555FF;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-button:disabled {
  background-color: #cccccc;
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #2555FF; /* Blue */
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.page-indicator {
  width: 77px;
  height: 5px;
  border-radius: 2.5px;
  margin: 0 5px;
}

.page-indicator-active {
  background-color: #2547C0;
}

.page-indicator-inactive {
  background-color: #D4D9E82E;
}
</style>
